import React from "react"
import { graphql } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import Breadcrumbs from "gatsby-theme-wirehead-bulma/src/breadcrumbs"

export default function PageTemplate({ data, location, pageContext }) {
  const { foodYaml } = data // data.markdownRemark holds your post data

  return (
    <Layout posted={pageContext.date} updated={pageContext.updated} >
      <SEO
        title={pageContext.title}
        description={pageContext.excerpt} />
      <Breadcrumbs
        depth={pageContext.depth}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <h1 className="title is-2">{pageContext.title}</h1>
      <section className="section">
      <div className="content">
      <div dangerouslySetInnerHTML={{__html: foodYaml.fields.preRecipe}} />
      </div>
      </section>
      <h2 className="title is-3">Ingredients</h2>
      <section className="section">
      <div className="content">
      <ul>
      {foodYaml.recipeIngredient.map((ingredient, x) => 
        <li key={x}>{ingredient}</li>
      )}
      </ul>
      </div>
      </section>
      <h2 className="title is-3">Preparation</h2>
      <section className="section">
      <div className="content">
      <ol>
      {foodYaml.recipeInstructions.map((instruction, x) => 
        <li key={x}>{instruction.step}</li>
      )}
      </ol>
      <div dangerouslySetInnerHTML={{__html: foodYaml.fields.postRecipe}} />
      </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    foodYaml(id: { eq: $id }) {
      id
      recipeIngredient
      title
      type
      description
      recipeInstructions {
        step
      }
      fields {
        preRecipe
        postRecipe
      }
    }
  }
`
